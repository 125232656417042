<template>
  <div class="col wrapper_for_sharing_tasks">
    <card>
      <div class="head_of_tasks" :class="{ is_rtl: isRtl() }">
        <h5 class="mb-2 the_task_name" :class="{ is_rtl: isRtl() }">
          <i class="fa fa-tasks mr-2"></i>
          <span class="text-muted">{{ teamTasks.name }} </span>
        </h5>
        <div class="icons_wrapping_edit_print">
          <el-tooltip
            class=" mb-2  edit-btn print-btn  share_score_button main_tool_tip"
            content="Edit Task"
            placement="top-start"
          >
            <el-button
              @click="add_team_task = true"
              type="success"
              class="mb-2  edit-btn "
              ><i class="fa fa-edit"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            class=" mb-2 mr-2 ml-2 edit-btn print-btn main_tool_tip"
            content="Print Task"
            placement="top-start"
          >
            <el-button
              @click="printTaskPage"
              type="success"
              class="mb-2 mr-2 ml-2 edit-btn "
              ><i class="fa fa-print"></i>
            </el-button>
          </el-tooltip>
          <!-- <el-tooltip
            class=" mb-2 mr-2 ml-2 edit-btn print-btn edit_score_button main_tool_tip"
            content="Share Task"
            placement="top-start"
          >
            <el-button
              @click="open_share_profile_dialog"
              type="success"
              class="mb-2 "
              ><i class="fa fa-share-alt icons_actions "></i>
            </el-button>
          </el-tooltip> -->
        </div>
      </div>
      <!-- <h4 class="text-capitalize">{{ teamTasks.name }}</h4> -->
      <el-collapse v-model="activeNames">
        <el-collapse-item
          :title="`${localization('Team Line Graph')}`"
          name="1"
        >
          <div
            class="the_main_wrapper_for_charts mt-4 mb-4"
            v-if="!loadingValue"
          >
            <div class="is_chart_wrapper">
              <chart-card
                v-if="scores.length > 0"
                :chart-data="teamChart"
                :chart-options="barChart.options"
                :chart-responsive-options="barChart.responsiveOptions"
                ref="chart"
              >
                <template slot="header">
                  <div class="d-flex">
                    <i
                      class="el-icon el-icon-data-analysis"
                      style="font-size: 30px; margin-right: 7px"
                    ></i>
                    <h4 class="card-title">
                      {{ localization("Team Line Graph") }}
                    </h4>
                  </div>
                </template>
              </chart-card>
              <div class="lines_in_chart avoid">
                <span class="is_text_chart">Avoid</span>
              </div>
              <div class="lines_in_chart needed">
                <span class="is_text_chart">As Needed</span>
              </div>
              <div class="lines_in_chart first">
                <span class="is_text_chart">Use First</span>
              </div>
            </div>
            <div class="for_line_graph_members_container">
              <div
                v-for="(member, index) in everyTeamMembers"
                :key="member.id"
                class="every_bullet_wrapper"
                :class="`member_${index + 1}`"
              >
                <span class="bullet"></span>
                <span class="text-capitalize"
                  >{{ member.first_name }} {{ member.last_name }}
                </span>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div class="is_description_here">
        <h5 class="title_with">task description</h5>
        <p
          :class="{ 'text-right': isRtl() }"
          class=" for-task-description"
          style="line-height: 2.1"
          v-html="teamTasks.description"
        ></p>
      </div>
      <!-- Start Strategies  -->
      <div>
        <el-collapse class="mt-2" v-model="panel" multiple>
          <div
            class="child"
            v-for="(item, i) in teamTasks.strategy_id"
            :key="i"
          >
            <el-collapse-item :name="`${i}`" multiple>
              <!-- //? start  collapse title -->
              <template #title>
                <div
                  class="main_collapse_title_wrapper "
                  style="width: 100%"
                  :class="{ is_rtl: isRtl() }"
                >
                  <div>
                    <h4 class="every_collapse_title">
                      <i
                        :class="`fa fa-star mr-2 ${i}`"
                        :style="`color: ${getProgressColor(i)}`"
                      ></i>

                      {{ localization(i) }}
                    </h4>
                  </div>
                </div>
              </template>
              <!-- //? end  collapse title -->
              <div class="p-2 set_bg_white_for_share">
                <div style="width: 100%; display: block" v-if="item.length > 0">
                  <!-- Unlimited strategies @change="select_strategies" in checkbox  -->
                  <el-checkbox-group
                    v-model="selected_tasks_attribute"
                    class="mt-2"
                    :text-color="getProgressColor(i)"
                    @change="handlerSelectedAttributeTasks"
                  >
                    <el-checkbox
                      v-for="(feedback, i) in item"
                      :key="i"
                      :label="feedback.id"
                      :value="feedback.id"
                      class="another-check-label"
                    >
                      <p class=" text-dark texst">
                        {{ feedback.level1 }}
                      </p>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </el-collapse-item>
          </div>
        </el-collapse>
      </div>

      <!-- End Strategies  -->
      <div
        class="team_routes_wrapper"
        :class="{ 'd-flex flex-row-reverse': isRtl() }"
      >
        <router-link class="back_link" to="/tackling-project"
          ><i class="fa fa-arrow-left back_icon mr-1"></i>
          {{ localization("previous") }}
        </router-link>
      </div>

      <!--// Start dialog for share task  -->
      <el-dialog
        :title="`${localization('Share Your Strategies')}`"
        :visible.sync="share_profile_dialog"
        top="4vh"
        :class="{ share_profile_with_rtl: isRtl() }"
      >
        <el-form
          :model="share_profile_data"
          :rules="share_profile_rules"
          ref="for_add_new_contact"
        >
          <el-form-item
            :label="`${localization('Email')}`"
            prop="email"
            :rules="[
              {
                required: true,
                message: 'Please input email address',
                trigger: 'blur'
              },
              {
                type: 'email',
                message: 'Please input correct email address',
                trigger: ['blur', 'change']
              }
            ]"
          >
            <el-input
              v-model="share_profile_data.email"
              autocomplete="off"
              style="width: 100%"
              :placeholder="`${localization('Email')}`"
              type="email"
            ></el-input>
          </el-form-item>
        </el-form>

        <div
          slot="footer"
          class="
            dialog-footer
            main_dialog_footer
          "
        >
          <el-button
            @click="close_share_profile_dialog"
            class="for_c_buttons text-center text-capitalize"
            >{{ localization("Cancel") }}</el-button
          >
          <el-button
            type="primary"
            class="
              text-center
              for_c_btns
              text-capitalize

            "
            @click="share_profile_page"
            >{{ localization("Send") }}</el-button
          >
        </div>
      </el-dialog>
      <!-- // End dialog for share task -->

      <!-- Start Add Task  -->
      <el-dialog
        title="Edit Task"
        :visible.sync="add_team_task"
        width="50%"
        top="4vh"
      >
        <el-form
          :model="teamTaskData"
          :rules="rules"
          ref="taskRef"
          @submit.prevent="handlerAddTeamTasks"
        >
          <el-form-item :label="`${localization('Name')}`" prop="name">
            <el-input
              :placeholder="`${localization('Name')}`"
              v-model="teamTaskData.name"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="`${localization('Description')}`"
            prop="description"
          >
            <el-input
              :placeholder="`${localization('Description')}`"
              v-model="teamTaskData.description"
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="
            dialog-footer

            main_dialog_footer
          "
        >
          <el-button
            @click="add_team_task = false"
            class="for_c_buttons text-center text-capitalize"
            >{{ localization("Cancel") }}</el-button
          >

          <el-button
            @click="handlerAddTeamTasks"
            class="
              text-center
              for_c_btns
              text-capitalize
            "
            >{{ localization("Save") }}</el-button
          >
        </div>
      </el-dialog>
      <!-- End Add Task  -->
    </card>
    <!-- <card>
      <div>
        {{ teamTasks.team_members }}
      </div>
    </card> -->
  </div>
</template>

<script>
import { Card } from "src/components/index";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import { ChartCard, StatsCard } from "src/components/index";
export default {
  data() {
    return {
      tasks: {
        Sequence: [
          {
            level1:
              "Don’t waste time re-reading the problem over and over. Read it and begin your work!",
            level2: "",
            id: 1693,
            pattern: "Sequence"
          },
          {
            level1:
              "Limit yourself to double checking your work one time and then move on.",
            level2: "",
            id: 1695,
            pattern: "Sequence"
          },
          {
            level1: "Don't become over confident and ignore an important step.",
            level2: "",
            id: 1721,
            pattern: "Sequence"
          },
          {
            level1:
              "Don't let your step-by-step approach keep you from completing each problem within the allowed time.",
            level2: "",
            id: 1722,
            pattern: "Sequence"
          }
        ],
        Precision: [
          {
            level1:
              "Read each problem carefully even if you are familiar with what you are being asked to do.",
            level2: "",
            id: 1725,
            pattern: "Precision"
          },
          {
            level1:
              "Focus on key words and numbers when doing word problems. Don't get stuck unimportant details.",
            level2: "",
            id: 1726,
            pattern: "Precision"
          },
          {
            level1:
              "Don't let your need for accuracy keep you from making a reasonable guess when you aren't certain of an answer.",
            level2: "",
            id: 1728,
            pattern: "Precision"
          },
          {
            level1:
              "Don't be over confident. Check all answers for correctness.",
            level2: "",
            id: 1730,
            pattern: "Precision"
          }
        ],
        "Technical Reasoning": [
          {
            level1:
              "Even if you can do your problem-solving in your head, don't. Put it down on paper. It's a requirement for credit!",
            level2: "",
            id: 1732,
            pattern: "Technical Reasoning"
          },
          {
            level1:
              "Read word problems carefully. Don't ignore the importance of each key word just because words aren't your thing.",
            level2: "",
            id: 1734,
            pattern: "Technical Reasoning"
          },
          {
            level1:
              "Sketch out your approach to doing any word problem. It will help you stay focused on the details.",
            level2: "",
            id: 1736,
            pattern: "Technical Reasoning"
          },
          {
            level1:
              "Use specific math terms when required to write an explanation of how you solved the problem. Just a numerical answer will not earn you credit!",
            level2: "",
            id: 1738,
            pattern: "Technical Reasoning"
          }
        ],
        Confluence: [
          {
            level1:
              "Take time to read and follow the assignments' instructions. Break the task into small steps staying focused on the overall outcome.",
            level2: "",
            id: 105,
            pattern: "Confluence"
          },
          {
            level1:
              "Don’t be tempted to ignore or make up your own Order of Operations. There is a reason you are to solve the problem using a specific order.",
            level2: "",
            id: 106,
            pattern: "Confluence"
          },
          {
            level1:
              "Don’t make up answers off the top of your head. Think them through and ask yourself, “Have I followed the steps required to get the correct answer? Have I used all the information provided? Is my answ",
            level2: null,
            id: 153,
            pattern: "Confluence"
          },
          {
            level1:
              "Don’t wait until the very last minute. Allow time to finish each step of the assignment and make it more complete. Often when you hurry you will make simple errors.",
            level2: "",
            id: 172,
            pattern: "Confluence"
          },
          {
            level1:
              "It is ok to plug in different values in the formula until I find the correct answer.",
            level2: "",
            id: 3048,
            pattern: "Confluence"
          }
        ]
      },
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      share_profile_dialog: false,
      share_profile_data: {},
      share_profile_rules: {
        email: [
          {
            required: true,
            type: "email",
            message: [
              "Please input email address",
              "Please input correct email address"
            ],
            trigger: ["blur", "change"]
          }
        ]
      },
      // edit task
      add_team_task: false,
      teamTaskData: {
        name: null,
        description: null
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      selected_tasks_attribute: [],
      options: {
        low: 0,
        showArea: true,
        height: "350px",
        axisX: {
          showGrid: true
        },
        lineSmooth: true,
        showLine: true,
        showPoint: true
      },
      barChart: {
        options: {
          seriesBarDistance: 20,
          showPoint: true,
          labelDirection: "explode",
          chartPadding: 0,
          labelOffset: 20,
          low: 7,
          high: 40,
          showLine: true,

          labelInterpolationFnc: function(value) {
            return `${value.split(" ")[0]}...`;
          },
          height: "350px",
          width: "95%",
          axisX: {
            offset: 30,
            position: "end",
            showLabel: true,
            showGrid: true,
            labelOffset: {
              x: 0,
              y: 5
            }
          },
          axisY: {
            offset: 50,
            position: "start",
            showLabel: true,
            showGrid: true,
            scaleMinSpace: 20,
            onlyInteger: true,
            labelOffset: {
              x: 0,
              y: 0
            }
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      everyTeamMembers: [],
      activeNames: ["1"]
    };
  },
  components: {
    Card,
    ChartCard,
    StatsCard
  },
  mounted() {
    this.getTaskData();
    // if (this.id) {
    //   this.Refresh();
    // }
  },
  computed: {
    loadingValue() {
      return this.$store.getters["user/usersLoad"];
    },
    teamTasks() {
      return this.$store.getters["user/getShowTeamTasks"];
    },
    scores() {
      let scores = [];
      this.everyTeamMembers.forEach(element => {
        scores.push([
          element.sequence,
          element.precision,
          element.technical_reasoning,
          element.confluence
        ]);
      });
      return scores;
    },
    teamChart() {
      // return this.teamChartTwo;
      let data = {};
      data.labels = [
        "Sequence",
        "Precision",
        "Technical Reasoning",
        "Confluence"
      ];
      // data.series = this.handlerScores;
      data.series = this.scores;
      return data;
    }
  },
  methods: {
    getTaskData() {
      this.$store
        .dispatch("user/handlerShowTeamTasks", this.$route.params.id)
        .then(res => {
          this.everyTeamMembers = res.task.team_members;
          if (this.teamTasks.selected_strategy_id != null) {
            this.selected_tasks_attribute = this.teamTasks.selected_strategy_id;
          } else {
            this.selected_tasks_attribute = [];
          }
          this.teamTaskData.name = this.teamTasks.name;
          this.teamTaskData.description = this.teamTasks.desc;
        });
    },
    printTaskPage() {
      window.print();
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    // getProgressValue(key) {
    //   switch (key) {
    //     case "Precision":
    //       return this.userScore.precision;
    //     case "Technical Reasoning":
    //       return this.userScore.technical_reasoning;
    //     case "Confluence":
    //       return this.userScore.confluence;
    //     case "Sequence":
    //       return this.userScore.sequence;
    //   }
    // },
    //// share profile page
    share_profile_page() {
      this.share_profile_dialog = true;
      this.$refs["for_add_new_contact"].validate(valid => {
        if (valid) {
          let cards = document.querySelector(".wrapper_for_sharing_tasks");
          html2canvas(cards, {
            logging: true,
            letterRendering: 1,
            allowTaint: true,
            useCORS: true
          }).then(canvas => {
            const payload = new FormData();
            canvas.toBlob(blob => {
              let img_to_blob = new File([blob], "profile.png", {
                type: "image/png"
              });
              payload.append("photo", img_to_blob);
              payload.append("email", this.share_profile_data.email);
              this.$store.dispatch("user/sharing_profile_task", payload);
              Swal.fire({
                title: "",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                icon: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              });
              this.share_profile_dialog = false;
              this.share_profile_data.email = "";
            }, "image/png");
          });
        }
      });
    },
    close_share_profile_dialog() {
      this.share_profile_dialog = false;
      this.share_profile_data.email = "";
    },
    open_share_profile_dialog() {
      this.share_profile_dialog = true;
    },

    // select_strategies() {
    //   this.task.selected_strategy_id = this.strategies;
    //   this.$store.dispatch("user/update_task", {
    //     id: `${this.id}`,
    //     query: { selected_strategy_id: this.strategies }
    //   });
    // },
    handlerAddTeamTasks() {
      this.$refs["taskRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/editNewTaskTalkingProject", {
              id: this.$route.params.id,
              query: this.teamTaskData
            })
            .then(res => {
              this.getTaskData();
              this.add_team_task = false;
              this.teamTaskData.name = null;
              this.teamTaskData.description = null;

              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        } else {
          return false;
        }
      });
    },
    handlerSelectedAttributeTasks() {
      this.$store.dispatch("user/update_task", {
        id: this.$route.params.id,
        query: { selected_strategy_id: this.selected_tasks_attribute }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.is_description_here {
  margin: 25px 0;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  .title_with {
    text-transform: capitalize;
  }
}
.the_main_wrapper_for_charts {
  // border-bottom: 1px solid #ccc;
  .is_chart_wrapper {
    position: relative;
  }
  .lines_in_chart {
    position: relative;
    bottom: 240px;
    padding: 0 5px;
    &::before {
      position: absolute;
      content: "";
      width: 87%;
      left: 5%;
      bottom: 0;
      border: 1px dashed #11bfe3;
    }
    &.avoid {
      &::before {
        bottom: -20px;
      }
      .is_text_chart {
        position: absolute;
        bottom: -75px;
        font-size: 12px;
      }
    }
    &.needed {
      bottom: 324px;
      &::before {
        bottom: -45px;
      }
      .is_text_chart {
        position: absolute;
        bottom: -75px;
        font-size: 12px;
      }
    }
    &.first {
      bottom: 443px;
      &::before {
        display: none;
      }
      .is_text_chart {
        position: absolute;
        bottom: -90px;
        font-size: 12px;
      }
    }
  }
}
.team_routes_wrapper {
  display: flex;
  gap: 10px;
  .back_link {
    color: #777;
    background-color: #eee;
    padding: 8px 10px;
    display: block;
    width: fit-content;
    margin: 10px 0;
    border-radius: 8px;
    transition: 0.3s;
    &:hover {
      background-color: #11bfe3;
      color: #fff;
    }
  }
}
.print-btn {
  background-color: #39c;
  border-color: #39c;
  transition: 0.2s;
  &.item {
    background-color: #f93;
    background-color: #c36;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    color: #fff !important;
    // .link-extention {
    // }
  }
  &:hover {
    opacity: 0.8;
  }
  &.edit_score_button {
    background-color: #3c3;
    border-color: #3c3;
  }
  &.share_score_button {
    background-color: #f93;
    border-color: #f93;
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
::v-deep .precision {
  /* margin: 5px; */
  display: inline-block;
  color: #3c3;
  // text-decoration: underline;
  margin: 0;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #3c3;
  }
  .span_for_tooltips {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    bottom: 100%;
    background-color: #333;
    color: #fff;
    min-width: 70px;
    text-align: center;
    display: none;
    transition: 0.5s;
    font-size: 16px;
    border-radius: 5px;
    z-index: 555;
    &:hover {
      display: none;
      pointer-events: none;
    }
    &::before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      border-style: solid;
      border-width: 10px;
      border-color: #333 transparent transparent transparent;
    }
  }
  &:hover {
    .span_for_tooltips {
      display: block;
    }
  }
}
::v-deep .technical_reasoning {
  display: inline-block;
  color: #f93;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #f93;
  }
  .span_for_tooltips {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    bottom: 100%;
    background-color: #333;
    color: #fff;
    min-width: 70px;
    text-align: center;
    display: none;
    transition: 0.5s;
    font-size: 16px;
    border-radius: 5px;
    z-index: 555;
    &:hover {
      display: none;
      pointer-events: none;
    }
    &::before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      border-style: solid;
      border-width: 10px;
      border-color: #333 transparent transparent transparent;
    }
  }
  &:hover {
    .span_for_tooltips {
      display: block;
    }
  }
}

::v-deep .sequence {
  display: inline-block;
  color: #39c;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  transition: 0.5s;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #39c;
  }
  &::after {
    position: absolute;
    // content: attr(data-tooltip);
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    bottom: 85%;
    background-color: #000;
    color: #fff;
    min-width: 70px;
    text-align: center;
    display: none;
    transition: 0.5s;
    font-size: 16px;
    border-radius: 5px;
  }
  .span_for_tooltips {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    bottom: 100%;
    background-color: #333;
    color: #fff;
    min-width: 70px;
    text-align: center;
    display: none;
    transition: 0.5s;
    font-size: 16px;
    border-radius: 5px;
    z-index: 555;
    &:hover {
      display: none;
      pointer-events: none;
    }
    &::before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      border-style: solid;
      border-width: 10px;
      border-color: #333 transparent transparent transparent;
    }
  }
  &:hover {
    .span_for_tooltips {
      display: block;
    }
  }
}

::v-deep .confluence {
  display: inline-block;
  color: #c36;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #c36;
  }
  .span_for_tooltips {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    bottom: 100%;
    background-color: #333;
    color: #fff;
    min-width: 70px;
    text-align: center;
    display: none;
    transition: 0.5s;
    font-size: 16px;
    border-radius: 5px;
    z-index: 555;
    &:hover {
      display: none;
      pointer-events: none;
    }
    &::before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      border-style: solid;
      border-width: 10px;
      border-color: #333 transparent transparent transparent;
    }
  }
  &:hover {
    .span_for_tooltips {
      display: block;
    }
  }
}

.head_of_tasks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  &.is_rtl {
    flex-direction: row-reverse;
  }

  .icons_wrapping_edit_print {
    display: flex;
    align-items: center;
    gap: 20px;
    @media print {
      display: none;
    }
    .print_task_button {
      // background-color: #fff;
      color: #fff;
      border: none;
      box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s all ease-in-out;
      background-color: #11bfe3;
      .icons_actions {
        font-size: 14px;
      }

      &.edit_task_button {
        background-color: #ffa534;
      }
      &.share {
        background-color: #3c3;
      }
    }
  }
}

.for-task-description,
.the_task_name {
  font-size: 1.2rem;
  margin-bottom: 30px;
  &.is_rtl {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    align-items: center;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}

.main_collapse_title_wrapper {
  // background: #ccc;
}
.every_collapse_title {
  // padding: 20px 0;
  margin: 0;
}
::v-deep .el-collapse-item__header {
  padding: 35px 0;
  font-size: 22px;
}
::v-deep .el-checkbox {
  width: 100%;
  display: flex;
}
::v-deep .el-checkbox__inner {
  border: 1px solid #000;
}
::v-deep .texst {
  word-break: break-word !important;
  word-wrap: break-word;
  white-space: normal;
  // white-space: pre-wrap;
  text-transform: initial;
  font-size: 1.2rem;
  line-height: 1;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
::v-deep .el-checkbox__label {
  word-break: break-word !important;
  white-space: pre-wrap;
  // word-wrap: break-word;
  white-space: normal;
  color: #000;
  padding: 0 7px;
}

.for_line_graph_members_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0;
  flex-wrap: wrap;
  .every_bullet_wrapper {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-bottom: 20px;
    .bullet {
      background: #eee;
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }
    &.member_1 {
      .bullet {
        background-color: black;
      }
    }
    &.member_2 {
      .bullet {
        background-color: #fe9d52;
      }
    }
    &.member_3 {
      .bullet {
        background-color: #4198d7;
      }
    }
    &.member_4 {
      .bullet {
        background-color: brown;
      }
    }
    &.member_5 {
      .bullet {
        background-color: #7a61ba;
      }
    }
  }
}
</style>

<style lang="scss">
.wrapper_for_sharing_tasks {
  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: black;
  }
  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: #fe9d52;
  }
  .ct-series-c .ct-line,
  .ct-series-c .ct-point {
    stroke: #4198d7;
  }
  .ct-series-d .ct-line,
  .ct-series-d .ct-point {
    stroke: brown;
  }
  .ct-series-e .ct-line,
  .ct-series-e .ct-point {
    stroke: #7a61ba;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .ct-label.ct-horizontal.ct-end {
      font-size: 10px !important;
    }
  }
  .is_chart_wrapper {
    .ct-chart-line {
      width: 90% !important;
    }
  }
}
</style>
